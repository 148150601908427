import React, { Fragment, useEffect, useState } from 'react';
import Tools from '../../js/tools'
import notie from 'notie'
import { User, Mail, Phone, Smartphone, Briefcase, Home } from 'react-feather';
import { NumericFormat } from 'react-number-format';
import { GetPermisos } from '../common/getPermisos';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumbv2'

const Default = ({
    esPrincipal,
    esEditar,
    noBtnAtras
}) => {
    const navigate = useNavigate()
    const params = useParams();
    const [form, setForm] = useState({});

    const guardar = () => {
        let formEmpresa = Tools.getForm('formEmpresa', { claveEtiquetas: '.txtVacios', ignore: [] })

        if (formEmpresa.isEmtyCount) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formEmpresa.isEmtyString})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: esEditar ? 'rt_empresas_editar' : 'rt_empresas_nueva',
            method: 'POST',
            body: {
                ...formEmpresa.data,
                idEm: params.idEm || ''
            },
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este contacto ya existe intente con otro correo`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                setTimeout(() => navigate('/empresa'), 2000)
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        if (!esEditar) {
            return
        }

        Tools.toolFetch({
            ruta: 'rt_empresa_detalle',
            method: 'POST',
            body: { idEm: params.idEm || '' },
        }, (response) => {
            let datos = response.datos

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setForm(datos)
        })
    }, [])

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Empresa'} parent={[{ titulo: 'empresa', link: '/empresa' }]} />
            <div className="container-fluid">
                <div className='row'>
                    <form id='formEmpresa' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            {/* <div className={`card-header d-flex justify-content-${!noBtnAtras ? 'between' : 'center'}`}>
                                {!noBtnAtras ? <button className="btn btn-regresar" type="button"
                                    onClick={(e) => navigate('/empresa')} >Volver al inicio</button> : ''}
                            </div> */}
                             <div className="card-header d-flex ">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/direccion-fiscal')
                                }}>Volver al inicio</button>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-12 mb-3">
                                                <h5 className='title-card-general'>Datos generales de la empresa</h5>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_empresa" name="txt_empresa"
                                                        type="text"
                                                        autoComplete='off'
                                                        defaultValue={form.NOMBRE_EM}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span class="icon-icono_Tienda"></span> Nombre de la empresa<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_empresa" style={{ display: 'none' }}><strong>Falta completar (Nombre comercial)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_contacto" name="txt_contacto"
                                                        type="text"
                                                        autoComplete='off'
                                                        defaultValue={form.NOMBRES_CONTACTO_EM}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span className="icon-icono_Cliente"></span> Nombre de contacto<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_contacto" style={{ display: 'none' }}><strong>Falta completar (Nombre de contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_apellidos" name="txt_apellidos" type="text"
                                                        autoComplete='off'
                                                        defaultValue={form.APELLIDOS_CONTACTO_EM}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span className="icon-icono_Cliente"></span> Apellidos de contacto<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_apellidos" style={{ display: 'none' }}><strong>Falta completar (Apellidos de contacto)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        id="correo_contacto"
                                                        name="correo_contacto"
                                                        type="email"
                                                        defaultValue={form.CORREO_CONTACTO_EM}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationCustomUsername"><i class="fa fa-envelope" aria-hidden="true"></i> Correo electrónico<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios correo_contacto" style={{ display: 'none' }}><strong>Falta completar (Correo electrónico)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className='input-field'>
                                                    <NumericFormat format="(###) ###-####" mask="_"
                                                        id="tel_contacto" name="tel_contacto"
                                                        autoComplete='off'
                                                        onChange={(data) => null}
                                                        value={form.TELEFONO_CONTACTO_EM}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Teléfono<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios tel_contacto" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className='input-field'>
                                                    <NumericFormat format="(###) ###-####" mask="_"
                                                        id="cel_contacto" name="cel_contacto"
                                                        autoComplete='off'
                                                        onChange={(data) => null}
                                                        value={form.CELULAR_CONTACTO_EM}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationCustomUsername"><i class="fa fa-phone" aria-hidden="true"></i> Celular</label>
                                                </div>
                                                <span className="text-danger txtVacios cel_contacto" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="justify-content-end d-flex">
                                                    {(esEditar && (GetPermisos.find(permiso => [12].includes(permiso)) ? true : false)) ||
                                                        (!esEditar && (GetPermisos.find(permiso => [2038].includes(permiso)) ? true : false)) ?
                                                        <button className="btn-nuevo d-flex align-items-center" type="button"
                                                            onClick={(e) => guardar()} >
                                                            <span className="text">Guardar</span>
                                                            <div className="icon-container">
                                                                <div className="icon icon--left">
                                                                </div>
                                                                <div className="icon icon--right">
                                                                </div>
                                                            </div>
                                                        </button>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default