import React, { Fragment, useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { Mail, Phone, Smartphone, User } from 'react-feather'
import Tools from '../../js/tools'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
// import { useNavigate, useParams } from 'react-router-dom'

const Default = ({}) => {
    const [formUsuario, setFormUsuario] = useState({})

    const guardar = () => {
        let _formUsuario = Tools.getForm('formUsuario', { claveEtiquetas: '.txtVacios' })

        if (_formUsuario.isEmtyCount) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formUsuario.isEmtyString})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
   
        Tools.toolFetch({
            ruta: 'rt_usuario_perfil_editar',
            method: 'POST',
            body: _formUsuario.data,
        },(response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'NO-EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este usuario no existe, no es posible hacer la actualización`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este correo ya esta en uso, intente con otro diferente`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Perfil actualizado correctamente`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarContra = () => {
        let _formContra = Tools.getForm('formContra', { claveEtiquetas: '.txtVacios' })

        if (_formContra.isEmtyCount) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formContra.isEmtyString})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }
   
        Tools.toolFetch({
            ruta: 'rt_usuario_perfil_editar_contra',
            method: 'POST',
            body: _formContra.data,
        },(response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'NO-EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este usuario no existe, no es posible hacer la actualización`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'LIMITE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `La contraseña debe de tener por lo  menos 8 caracteres`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'NO-COINCIDE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `La contraseña no coincide, vuelve a ingresar los datos`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setFormUsuario((s) => ({...s, txt_contra:'',txt_confirm_contra:''}))

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Perfil actualizado correctamente`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_usuario_perfil',
            method: 'POST',
            body: {},
        }, (response) => {
            let datos = response.datos

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setFormUsuario(datos)
        })
    }, [])

    return (
        <Fragment>
            <Breadcrumb icon={User} title={'Perfil usuario'} parent={['perfil']} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-center">
                                <h4><strong>EDITAR PERFIL</strong></h4>
                            </div>
                            <div className='row card-body'>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <form className="form theme-form needs-validation tooltip-validation /* - */ row" id='formUsuario'>
                                        {/* <div className={`form theme-form needs-validation tooltip-validation form-row`}> */}
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_nombres" name="txt_nombres" type="text"
                                                        autoComplete='off'
                                                        defaultValue={formUsuario.NOMBRES_UA}
                                                        required spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span className="icon-icono_Cliente"></span> Nombres<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_nombres" style={{ display: 'none' }}><strong>Falta completar (Nombres)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_apellidos" name="txt_apellidos" type="text"
                                                        autoComplete='off'
                                                        defaultValue={formUsuario.APELLIDOS_UA}
                                                        required spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span className="icon-icono_Cliente"></span> Apellidos<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_apellidos" style={{ display: 'none' }}><strong>Falta completar (Apellidos)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        id="txt_correo"
                                                        name="txt_correo"
                                                        type="email"
                                                        defaultValue={formUsuario.CORREO_UA}
                                                        required spellcheck="false"
                                                    />
                                                    <label htmlFor="validationCustomUsername"><i class="fa fa-envelope" aria-hidden="true"></i> Correo electrónico<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_correo" style={{ display: 'none' }}><strong>Falta completar (Correo electrónico)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className='input-field'>
                                                    <NumericFormat format="(###) ###-####" mask="_"
                                                        id="txt_cel" name="txt_cel"
                                                        autoComplete='off'
                                                        onValueChange={(d) => setFormUsuario((s) => ({...s, CELULAR_UA: d.value}))}
                                                        value={formUsuario.CELULAR_UA}
                                                        required spellcheck="false"
                                                    />
                                                    <label htmlFor="validationCustomUsername"><i class="fa fa-phone" aria-hidden="true"></i> Celular<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_cel" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <NumericFormat format="(###) ###-####" mask="_"
                                                        id="txt_tel" name="txt_tel"
                                                        autoComplete='off'
                                                        onValueChange={(d) => setFormUsuario((s) => ({...s, TELEFONO_UA: d.value}))}
                                                        value={formUsuario.TELEFONO_UA}
                                                        required spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Teléfono</label>
                                                </div>
                                                <span className="text-danger txtVacios txt_tel" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                            </div>
                                        {/* </div> */}
                                    </form>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mt-2">
                                    <div className="text-left">
                                        <button className="btn-nuevo" type="button"
                                            onClick={() => guardar()}>
                                                <span className="text">Editar información</span>
                                                    <div className="icon-container password">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                            </button>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                                    <div className='row'>
                                        <div className='col-12 mb-3'>
                                            <h6><strong>Editar contraseña</strong></h6>
                                        </div>
                                    </div>
                                    <form className="form needs-validation tooltip-validation /* -- */ row" id='formContra'>
                                        {/* <div className={`form theme-form needs-validation tooltip-validation form-row`}> */}
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input
                                                        id="txt_contra" name="txt_contra" type="password"
                                                        autoComplete='off'
                                                        value={formUsuario.txt_contra || ''}
                                                        onChange={(e) => setFormUsuario((s) => ({...s, txt_contra:e.target.value}))}
                                                        required spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span class="icon-icono_Contrasena"></span> Contraseña<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_contra" style={{ display: 'none' }}><strong>Falta completar (Contraseña)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input
                                                        id="txt_confirm_contra" name="txt_confirm_contra" type="password"
                                                        autoComplete='off'
                                                        value={formUsuario.txt_confirm_contra || ''}
                                                        onChange={(e) => setFormUsuario((s) => ({...s, txt_confirm_contra:e.target.value}))}
                                                        required spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span class="icon-icono_Contrasena"></span> Confirmar contraseña<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_confirm_contra" style={{ display: 'none' }}><strong>Falta completar (Confirmar contraseña)</strong></span>
                                            </div>
                                        {/* </div> */}
                                    </form>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mt-3">
                                    <div className="text-left">
                                        <button className="btn-nuevo" type="button"
                                            onClick={() => guardarContra()}>
                                                    <span className="text">Editar contraseña</span>
                                                    <div className="icon-container password">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;