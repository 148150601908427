import React, { Fragment, useState, useEffect } from 'react';
import { Home } from 'react-feather';
import { GetPermisos } from '../common/getPermisos';
import notie from 'notie';
import Tools from '../../js/tools';
import Breadcrumb from '../common/breadcrumbv2';
import Tabla from '../common/tabla';
import { useNavigate } from 'react-router-dom';

const Default = (props) => {
    /* ==================== HOOKS ==================== */

    /* ==================== CONSTANTES ==================== */
    const navigate = useNavigate();

    /* ==================== USE EFFECT ==================== */
    useEffect(() => {

    }, [])
    /* ==================== FUNCIONES ==================== */

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Usuarios'} parent={[{ titulo: 'usuarios', link: '/usuarios-cms' }]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <Tabla
                                    cargando={false}
                                    htmlBtnPag={
                                        <div>
                                            {(GetPermisos.find(permiso => [1037].includes(permiso)) ? true : false) ?
                                                <button
                                                    className="btn-nuevo"
                                                    type="button"
                                                    onClick={() => {
                                                        navigate('/usuarios-cms/nuevo')
                                                    }}
                                                >
                                                    <span className="text">Nuevo</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button>
                                                : ''
                                            }
                                        </div>
                                    }
                                    headers={[
                                        { nombre: 'ID', texto: 'ID' },
                                        { nombre: 'Nombre', texto: 'NOMBRE' },
                                        { nombre: 'Correo', texto: 'CORREO' },
                                        { nombre: 'Telefono', texto: 'TELEFONO' },
                                        { nombre: 'Alta', texto: 'ALTA' },

                                        {
                                            nombre: 'Detalles', texto: 'Detalle', tipo: 'html',
                                            html: (d) => {
                                                return <div>
                                                    <button
                                                        className="btn btn-outline-success"
                                                        type="button"
                                                        onClick={() => {
                                                            navigate(`/usuarios-cms/detalle/${d.ID}`);
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                                    </button>
                                                </div>
                                            }
                                        }
                                    ]}
                                    totalField={'NR'} /* NR = número de resultados totales */
                                    path={'rt_usuarios_registros'}
                                    // refresh={esRefresh}
                                    body={{}}
                                    paginadorTop={true}
                                >
                                </Tabla>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>

        </Fragment>
    )
}

export default Default;