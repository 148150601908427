import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumbv2'
import Table from '../common/tabla'
import { Home } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import notie from 'notie'

const Default = ({
    esPrincipal,
    setSelected
}) => {
    const [esRefresh, setEsRefresh] = useState(true)
    const navigate = useNavigate()
    const params = useParams();

    return <Fragment>
        {esPrincipal ? <Breadcrumb icon={Home} title={'DATOS FISCALES'} parent={[{ titulo: 'empresa', link: '/empresa' }]} /> : ''}
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-xl-12 col-md-12">
                                    <Table
                                        noRutaDinamica={true}
                                        cargando={false}
                                        htmlBtnPag={
                                            <button
                                                className="btn-nuevo"
                                                type="button"
                                                onClick={() => {
                                                    if (setSelected) {
                                                        navigate(`/empresa/${params.idEm}/datos-fiscales/nuevo`)
                                                        setSelected('df-form')
                                                    } else {
                                                        navigate('/empresa/datos-fiscales/nuevo')
                                                    }
                                                }}
                                            >
                                                <span className="text">Nuevo</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button>}
                                        headers={[
                                            {
                                                nombre: 'DATOS FISCALES', tipo: 'html', texto: 'ALIAS_EDF', html: (d) => {
                                                    return <div style={{ width: '25em' }}>
                                                        <strong>{d.ALIAS_EDF}</strong>
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'CORREO', tipo: 'html', texto: 'CORREO_EDF', html: (d) => {
                                                    return <div style={{ width: '25em' }}>
                                                        {d.CORREO_EDF}
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'RAZON SOCIAL', tipo: 'html', texto: 'RAZON_SOCIAL_EDF', html: (d) => {
                                                    return <div style={{ width: '25em' }}>
                                                        {d.RAZON_SOCIAL_EDF}
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'RFC', tipo: 'html', texto: 'RFC_EDF', html: (d) => {
                                                    return <div style={{ width: '25em' }}>
                                                        {d.RFC_EDF}
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                                html: (d) => {
                                                    return <div>
                                                        <BtnVer
                                                            onClick={() => {
                                                                if (setSelected) {
                                                                    setSelected('df-form-editar')
                                                                    navigate(`/empresa/${params.idEm}/datos-fiscales/${d.ID_EDF}`)
                                                                } else {
                                                                    navigate(`/empresa/datos-fiscales/${d.ID_EDF}`)
                                                                }
                                                            }}
                                                        />
                                                        {(GetPermisos.find(permiso => [2042].includes(permiso)) ? true : false) ?
                                                            <BtnEliminar
                                                                onClick={() => {
                                                                    notie.confirm({
                                                                        text: `Se eliminara <strong>${d.NOMBRE_PS}</strong> ¿estas de acuerdo?`,
                                                                        submitText: 'Si, eliminar',
                                                                        cancelText: 'No, cancelar',
                                                                        cancelCallback: function (value) {
                                                                        },
                                                                        submitCallback: function (value) {
                                                                        },
                                                                    })
                                                                }}
                                                            />
                                                            : ''
                                                        }
                                                    </div>
                                                }
                                            }
                                        ]}
                                        totalField={'NR'}
                                        path={'/rt_empresas_df'}
                                        refresh={esRefresh}
                                        body={{ id: params.idEm }}
                                        rowsPerPage={20}
                                        paginadorTop={true}
                                    >
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>                    
                </div>
            </div>
        </div>

    </Fragment>
}

export default Default