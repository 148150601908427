import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Home } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_clientes_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Clientes'} parent={[{ titulo: 'clientes', link: '/clientes' }]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-xl-12 col-md-12">
                                    <Table
                                        noRutaDinamica={true}
                                        cargando={false}
                                        htmlBtnPag={
                                            <div>
                                                <div className="btn-container">
                                                    {(GetPermisos.find(permiso => [2069].includes(permiso)) ? true : false) ?
                                                        <Fragment>
                                                        
                                                                <button className='btn-nuevo' onClick={() => {
                                                                    navigate('/clientes/nuevo')
                                                                }}>
                                                                    <span className="text">Nuevo</span>
                                                                    <div className="icon-container">
                                                                        <div className="icon icon--left">
                                                                        </div>
                                                                        <div className="icon icon--right">
                                                                        </div>
                                                                    </div>
                                                                </button>                                                            
                                                            

                                                        </Fragment> : ''
                                                    }
                                                </div>
                                            </div>
                                        }
                                        headers={[
                                            {
                                                nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_CLM', html: (d) => {
                                                    return <div>
                                                        <strong>{d.NOMBRE_CLM}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'APELLIDOS', tipo: 'html', texto: 'APELLIDOS_CLM', html: (d) => {
                                                    return <div>
                                                        <strong>{d.APELLIDOS_CLM}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'CALLE', tipo: 'html', texto: 'CALLE_CLM', html: (d) => {
                                                    return <div>
                                                        <strong>{d.CALLE_CLM}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'COLONIA', tipo: 'html', texto: 'COLONIA_CLM', html: (d) => {
                                                    return <div>
                                                        <strong>{d.COLONIA_CLM}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'CELULAR', tipo: 'html', texto: 'CELULAR_CLM', html: (d) => {
                                                    return <div>
                                                        <strong>{d.CELULAR_CLM}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                                html: (d) => {
                                                    return <div>
                                                        <BtnVer
                                                            onClick={() => {
                                                                navigate(`/clientes/${d.ID_CLM}`)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            }
                                        ]}
                                        totalField={'NR'} /* NR = número de resultados totales */
                                        path={'/rt_clientes_list'}
                                        refresh={esRefresh}
                                        body={{}}
                                        paginadorTop={true}
                                    >
                                    </Table>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Default;