import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Home } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formPrvCont, setFormPrvCont] = useState({})
    const navigate = useNavigate()
    const params = useParams();

    useEffect(() => {
        if (!esEditar) return

        Tools.toolFetch({
            ruta: 'rt_proveedores_contacto_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            if (response.status === 'OK') {
                setFormPrvCont(response.result[0])
            }
        })
    }, [])

    const onClickProv = () => {
        const form = Tools.getForm('formPrvCont')
        console.log(form);

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.idPrv = params.idPrv
        form.data.idPrc = params.idPrc
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_proveedores_contacto_nuevo' : 'rt_proveedores_contacto_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/proveedores/' + params.idPrv)
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Proveedores'} parent={[{ titulo: 'proveedores', link: '/proveedores' }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formPrvCont' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">

                                {/* <div className="btn-container">
                                    <button className='btn-nuevo' type='button' onClick={(e) => {
                                        // navigate('/servicios')
                                        window.location.href = '/proveedores'
                                    }}>
                                        <span className="text">Regresar</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>
                                </div> */}
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/proveedores/' + params.idPrv)

                                }}>Volver al inicio</button>
                               {/*  <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} PROVEEDOR</strong></h4>
                                <h4></h4> */}
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h5 className='title-card-general'>Datos generales del proveedor</h5>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                defaultValue={formPrvCont.NOMBRE_PRC}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Cliente"></span> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_correo" name="txt_correo"
                                                type="text"
                                                defaultValue={formPrvCont ? formPrvCont.CORREO_PRC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-envelope" aria-hidden="true"></i> Correo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_correo" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_telefono" name="txt_telefono"
                                                type="text"
                                                defaultValue={formPrvCont ? formPrvCont.TELEFONO_PRC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Teléfono<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_celular" name="txt_celular"
                                                type="text"
                                                defaultValue={formPrvCont ? formPrvCont.CELULAR_PRC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Celular<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_celular" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2057].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [15].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickProv}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default
