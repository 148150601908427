import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Home } from 'react-feather';
import { GetPermisos } from '../common/getPermisos';
import InputLabel from './inputLabel'
import notie from 'notie';
import Tools from '../../js/tools';
import Breadcrumb from '../common/breadcrumbv2';

const Default = ({ esEditar }) => {
    /* ==================== HOOKS ==================== */
    const params = useParams();
    const navigate = useNavigate()
    const [catalogos, setCatalogos] = useState({
        empresas: [],
        tipos: [],
        permisos: []
    });
    const [permiso, setPermiso] = useState(false)
    const [opt, setOpt] = useState({})
    const [verify, setVerify] = useState(false)

    const [datosUsuario, setDatosUsuario] = useState({});

    /* ==================== CONSTANTES ==================== */

    /* ==================== USE EFFECT ==================== */
    useEffect(() => {
        setPermiso(
            (!esEditar && (GetPermisos.find(permiso => [1037].includes(permiso)) ? true : false)) ||
            (esEditar && (GetPermisos.find(permiso => [2065].includes(permiso)) ? true : false))
        )

        fetch('../../rt_usuarios_catalogos_detalle', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (res) { return res.json(); })
            .then(function (res) {
                let permisos = {}

                if (res.estatus === 'OK') {
                    console.log('res', res);
                    let seccionesPermisos = groupArrayByKey(res.datos.permisos, 'id_sec', 'id');

                    let sec = res.datos.secciones.map(seccion => {
                        return {
                            id: seccion.id,
                            titulo: seccion.nombre,
                            permisos: seccionesPermisos[seccion.id]?.array || []
                        }
                    });

                    setCatalogos(res.datos);

                    if (!esEditar) {
                        setDatosUsuario({})
                        setOpt({})
                        return
                    }

                    setDatosUsuario((s) => ({ ...res.datos.usuario }));

                    res.datos.permisos.forEach(v => {
                        JSON.parse(v.OPCIONES).forEach(e => {
                            if (e.ACTIVO) {
                                if (!permisos[v.ID_PER]) permisos[v.ID_PER] = {}
                                permisos[v.ID_PER][e.ID_PER] = { id: e.ID_PER }
                            }
                        })
                    })

                    setOpt(permisos)

                    res.datos.permisosSecciones = sec;
                    res.datos.secciones = null;

                }

                else
                    throw Error(res.estatus);
            }).catch(function (err) {
                notie.alert({ text: 'No se pudo obtener los catálogos, inténtelo más tarde', type: 4, time: 5 });
                console.log('err', err);
            })
    }, [esEditar])

    /* ==================== FUNCIONES ==================== */
    const groupArrayByKey = (xs, key, param) => {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || { key: x[param], array: [] }).array.push(x);
            return rv;
        }, {});
    }

    const enviarDatosFormulario = () => {
        let listPermisos = []
        let datos = Tools.getForm('form_usuario', { ignore: ['input_CELULAR', 'input_TELEFONO'] });
        Object.values(opt).filter((v) => v).map(e => {
            Object.values(e).forEach(j => {
                if (j.id) listPermisos.push(j.id)
            });
        })

        console.log(listPermisos);

        if (datos.isEmtyCount > 0) {
            notie.alert({ text: `El campo ${datos.isEmtyLabel[datos.keys[0]]} es requerido`, type: 3, time: 4 });
            return;
        }

        datos.data['input_ID_UC'] = params.id;
        datos.data.permisos = listPermisos

        // let form_permisos = new FormData(document.getElementById('form_permisos'));
        // let permisos_form = [], permiso_split, e;
        // for (e of form_permisos.entries()) {
        //     permiso_split = e[0].split('_');
        //     permisos_form.push({
        //         id: permiso_split[2]
        //     });
        // };
        // datos.data['permisos'] = permisos_form;

        fetch(esEditar ? '../../rt_usuarios_editar' : '../../rt_agregar_usuario_permisos', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(res => {
                console.log('El res es este', res);
                if (res.datos.ESTATUS === 'AGREGADO') {
                    return notie.alert({ text: 'Información actualizada correctamente', type: 1, time: 5 });
                }
                if (res.datos.ESTATUS === 'EXISTENTE') {
                    return notie.alert({ text: 'Ingrese un correo diferente', type: 3, time: 5 });
                }
                else {
                    throw Error(res.datos.ESTATUS)
                }
            }).catch(err => {
                notie.alert({ text: 'No se pudo actualizar la información, inténtelo más tarde', type: 3, time: 6 });
                console.log(err);
            })
    }

    const ComponentePermisos = (propsPermisos) => {
        console.log('propsPermisos', propsPermisos);
        return (
            <Fragment>
                <div className='card-permisos'>
                    <div><h5>{propsPermisos.titulo}</h5></div>
                    <hr className='solid'></hr>
                    {
                        propsPermisos.permisos.map((permiso) => {
                            let id = `input_${propsPermisos.id}_${permiso.id}`;

                            return <div key={`key_permiso_${permiso.id}`} className="checkbox">
                                <input id={id} name={id} type="checkbox" value={'1'} defaultChecked={permiso.activo} />
                                <label htmlFor={id}>{permiso.nombre}</label>
                            </div>
                        }
                        )}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={(esEditar ? 'Editar' : 'Nuevo') + ' usuario'} parent={[{ titulo: 'usuarios', link: '/usuarios-cms' }, { titulo: `${esEditar ? 'editar' : 'nuevo'} usuario`, link: `/usuarios-cms/detalle/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header d-flex gap-3">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/usuarios-cms')
                                }}>Volver al inicio</button>
                            </div>
                            <div className="card-body">
                                <form id='form_usuario' className="theme-form">
                                    <div className='form-group row'>
                                        <div class="col-md-12 mb-3">
                                            <h5 className='title-card-general'>Datos generales del usuario</h5>
                                        </div>
                                        <div className='col-12 col-md-6 mb-4'>
                                            <div className='input-field select'>
                                                <select
                                                    className='form-select'
                                                    data-label='empresa'
                                                    name='input_EMPRESA'
                                                    id='input_EMPRESA'
                                                    value={datosUsuario?.ID_EM || ''} onChange={(e) => { setDatosUsuario((s) => ({ ...datosUsuario, ID_EM: e.target.value })); }} required spellcheck="false">
                                                    <option hidden value={''}>Seleccione</option>
                                                    {catalogos.empresas.map(item => <option key={`key_option_empresas_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>)}
                                                </select>
                                                <label className='col-form-label' htmlFor="input_EMPRESA"><i class="fa fa-briefcase" aria-hidden="true"></i> Empresa</label>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 mb-4'>
                                            <div className='input-field'>
                                                <input
                                                    name='input_NOMBRES'
                                                    id='input_NOMBRES'
                                                    type="text"
                                                    data-label="nombres"
                                                    defaultValue={datosUsuario.NOMBRES}
                                                    required s
                                                    pellcheck="false" />
                                                <label className="col-form-label" htmlFor="input_NOMBRES"><span className="icon-icono_Cliente"></span> Nombres</label>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 mb-4'>
                                            <div className='input-field'>
                                                <input
                                                    name='input_APELLIDOS'
                                                    id='input_APELLIDOS'
                                                    type="text"
                                                    data-label="apellidos"
                                                    value={datosUsuario.APELLIDOS}
                                                    required spellcheck="false"
                                                />
                                                <label className="col-form-label" htmlFor="input_APELLIDOS"><span className="icon-icono_Cliente"></span> Apellidos</label>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 mb-4'>
                                            <div className='input-field'>
                                                <input
                                                    name='input_CORREO'
                                                    id='input_CORREO'
                                                    type="text"
                                                    data-label="correo"
                                                    defaultValue={datosUsuario.CORREO}
                                                    required
                                                    spellcheck="false" />
                                                <label className="col-form-label" htmlFor="input_CORREO"><i class="fa fa-envelope" aria-hidden="true"></i> Correo</label>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 mb-4'>
                                            <div className='input-field'>
                                                <input
                                                    name='input_TELEFONO'
                                                    id='input_TELEFONO'
                                                    type="text"
                                                    data-label="telefono"
                                                    defaultValue={datosUsuario.TELEFONO}
                                                    required
                                                    spellcheck="false" />
                                                <label className="col-form-label" htmlFor="input_TELEFONO"><i class="fa fa-phone" aria-hidden="true"></i> Telefono</label>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 mb-4'>
                                            <div className='input-field'>
                                                <input
                                                    name='input_CELULAR'
                                                    id='input_CELULAR'
                                                    type="text"
                                                    data-label="celular"
                                                    defaultValue={datosUsuario.CELULAR}
                                                    required
                                                    spellcheck="false" />
                                                <label className="col-form-label" htmlFor="input_CELULAR"><i class="fa fa-phone" aria-hidden="true"></i> Celular</label>
                                            </div>
                                        </div>
                                        {/* <div className='col-12 col-md-6 mb-4'>
                                            <div className='input-field select'>
                                                <select
                                                    className='form-select'
                                                    data-label='tipo de usuario'
                                                    name='input_TIPO'
                                                    id='input_TIPO'
                                                    value={datosUsuario?.TIPO || ''}
                                                    onChange={(e) => { setDatosUsuario((s) => ({ ...datosUsuario, TIPO: e.target.value })); }} required spellcheck="false">
                                                    <option key={'0_input_TIPO'} hidden value={''}>Seleccione</option>
                                                    {catalogos.tipos.map(item => <option key={`key_option_tipos_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>)}
                                                </select>
                                                <label className='col-form-label' htmlFor="input_TIPO"><span className="icon-icono_Cliente"></span> Tipo usuario</label>
                                            </div>
                                        </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        {/* <div className="container-fluid d-print-none">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col">
                                        <div className="page-header-left">
                                            <h3>Asignar permisos</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='card'>
                            <div className='card-body'>
                                <form id='form_permisos' className="theme-form">
                                    <div className='row'>
                                        <div class="col-md-12 mb-3">
                                            <h5 className='title-card-general'>Asignar permisos</h5>
                                        </div>
                                        <div className='container-fluid'>
                                            <div className="row">
                                                {catalogos.permisos.map((v) =>
                                                    <div className="col-sm-14 col-xl-4">
                                                        <div className="ribbon-wrapper card">
                                                            <div className="card-body">
                                                                <div className="ribbon ribbon-primary">
                                                                    <label className="d-block" for={"chk-ani-" + v.ID_PER}>
                                                                        <input
                                                                            className="checkbox_animated"
                                                                            id={"chk-ani-" + v.ID_PER}
                                                                            type="checkbox"
                                                                            checked={opt[v.ID_PER] ? true : false}
                                                                            onClick={(s) => {
                                                                                const ops = opt
                                                                                if (s.target.checked) {
                                                                                    ops[v.ID_PER] = {}
                                                                                    ops[v.ID_PER][v.PERMISO_VER] = { id: v.PERMISO_VER }
                                                                                } else {
                                                                                    ops[v.ID_PER] = false
                                                                                }
                                                                                setVerify(!verify)
                                                                                setOpt(ops)
                                                                                console.log(ops);
                                                                            }}
                                                                        />
                                                                        {v.NOMBRE_PER} (Ver)
                                                                    </label>
                                                                </div>
                                                                {(opt[v.ID_PER] ? true : false) ? JSON.parse(v.OPCIONES).map((e) =>
                                                                    <Fragment>
                                                                        {e.MOSTRAR ?
                                                                            <InputLabel
                                                                                checked={e.ACTIVO ? true : false}
                                                                                opcion={e.OPCION}
                                                                                callback={(checked) => {
                                                                                    const ops = opt
                                                                                    opt[v.ID_PER][e.ID_PER] = checked ? { id: e.ID_PER } : false
                                                                                    setOpt(ops)
                                                                                }}
                                                                            />
                                                                            : ''}
                                                                    </Fragment>
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-12 text-center'>
                                            <div className='d-flex justify-content-end'>
                                                {permiso ?
                                                    <button className='btn-nuevo' type='button' onClick={enviarDatosFormulario}>
                                                        <span className="text">Guardar</span>
                                                        <div className="icon-container">
                                                            <div className="icon icon--left">
                                                            </div>
                                                            <div className="icon icon--right">
                                                            </div>
                                                        </div>
                                                    </button>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Default;