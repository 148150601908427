import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Home } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { GetPermisos } from '../common/getPermisos'
import { useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formAlm, setFormAlm] = useState({})
    const [ctgEstados, setCtgEstados] = useState([])
    const [valEstado, setValEstado] = useState([])
    const [valDepartamento, setValDepartamento] = useState([])
    const [ctgCiudades, setCtgCiudades] = useState([])
    const [ctgDepartamentos, setCtgDepartamentos] = useState([])
    const [ctgUsuarios, setCtgUsuarios] = useState([])
    const [valCiudad, setValCiudad] = useState([])
    const [valUs, setValUs] = useState([])
    const navigate = useNavigate()
    const params = useParams();

    const [catalogos, setCatalogos] = useState({})

    useEffect(() => {
        return Tools.toolFetch({
            ruta: 'rt_almacenes_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            console.log('el response', response);

            if (response.status === 'OK') {
                setCtgEstados(response.estados.datos)
                setCtgCiudades(response.ciudades.datos)
                setCtgDepartamentos(response.departamentos.datos)
                setCtgUsuarios(response.usuarios)
                if (esEditar) {
                    setFormAlm(response.result[0][0])
                    setValDepartamento(response.result[0][0].ID_DEP_ALM)
                    setValEstado(response.result[0][0].ID_CES_ALM)
                    setValCiudad(response.result[0][0].ID_CCD_ALM)
                    setValUs(response.result[1])
                } else {
                    setFormAlm({})
                    setValDepartamento(0)
                    setValEstado(0)
                    setValCiudad(0)
                    setValUs([])
                }
            }
        })
    }, [esEditar])

    const onChangeEstados = (clave) => {
        setValEstado(clave)
        Tools.toolFetch({
            ruta: 'rt_almacenes_change_estados',
            method: 'POST',
            body: { clave },
        }, (response) => {
            console.log(response);

            if (response.estatus === 'OK') {
                setCtgCiudades(response.datos)
            }
        })
    }

    const onClickAlm = () => {
        const form = Tools.getForm('formAlm', { ignore: ['txt_num_ext'] })
        console.log(form.data);

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.id = params.id
        form.data.usuarios = valUs.map(v => v.value)
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_almacenes_nuevo' : 'rt_almacenes_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/almacenes')
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Almacenes'} parent={[{ titulo: 'almacenes', link: '/almacenes' }, { titulo: `${esEditar ? 'editar' : 'nuevo'} almacen`, link: `/almacenes/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formAlm' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/almacenes')
                                }}>Volver al inicio</button>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h5 className='title-card-general'>Datos generales del almacen</h5>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                maxLength={200}
                                                defaultValue={formAlm ? formAlm.NOMBRE_ALM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-building" aria-hidden="true"></i> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                maxLength={200}
                                                defaultValue={formAlm ? formAlm.TITULO_ALM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Título)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Departamentos"
                                                placeholder="Seleccionar departamento"
                                                id="clave_departamento"
                                                name="clave_departamento"
                                                value={valDepartamento}
                                                datos={ctgDepartamentos}
                                                onChange={(e) => { setValDepartamento(e.item.clave) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Departamento<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_departamento" style={{ display: 'none' }}><strong>Falta completar (Departamento)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Estado"
                                                placeholder="Seleccionar estado"
                                                id="clave_estado"
                                                name="clave_estado"
                                                value={valEstado}
                                                datos={ctgEstados}
                                                onChange={(e) => {
                                                    onChangeEstados(e.item.clave)
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Estado<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_estado" style={{ display: 'none' }}><strong>Falta completar (Estado)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Ciudad"
                                                placeholder="Seleccionar ciudad"
                                                id="clave_ciudad"
                                                name="clave_ciudad"
                                                value={valCiudad}
                                                datos={ctgCiudades}
                                                onChange={(e) => { setValCiudad(e.item.clave) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Ciudad<span className="text-danger">*</span></label>
                                        </div>
                                        {/* <div className="input-group inputubicacion">
                                            <div className="input-group-prepend"></div>
                                            <select className='form-select' onChange={() => null} value={valCiudad} data-label='tipo de usuario' name='clave_ciudad' id='clave_ciudad'>
                                                <option hidden value={''}>Seleccione</option>
                                                {
                                                    ciudades.map(item =>
                                                        <option key={`key_option_tipos_${item.clave}`} value={item.clave}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div> */}
                                        <span className="text-danger txtVacios clave_ciudad" style={{ display: 'none' }}><strong>Falta completar (Ciudad)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_calle" name="txt_calle"
                                                type="text"
                                                maxLength={200}
                                                defaultValue={formAlm ? formAlm.CALLE_ALM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Calle<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_calle" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_num_int" name="txt_num_int"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.NUM_INT_ALM : ''}
                                                maxLength={50}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Numero interno<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_num_int" style={{ display: 'none' }}><strong>Falta completar (Número interno)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_num_ext" name="txt_num_ext"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.NUM_EXT_ALM : ''}
                                                maxLength={50}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Número externo</label>
                                        </div>
                                        {/* <span className="text-danger txtVacios txt_num_ext" style={{ display: 'none' }}><strong>Falta completar (Número externo)</strong></span> */}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_col" name="txt_col"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.COLONIA_ALM : ''}
                                                maxLength={200}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Colonia<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_col" style={{ display: 'none' }}><strong>Falta completar (Colonia)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_cp" name="txt_cp"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.CP_ALM : ''}
                                                maxLength={10}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Código postal<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (Código postal)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_ref" name="txt_ref"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.REFERENCIA_ALM : ''}
                                                maxLength={500}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Referencia<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_ref" style={{ display: 'none' }}><strong>Falta completar (Referencia)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_longitud" name="txt_longitud"
                                                type="text"
                                                maxLength={30}
                                                defaultValue={formAlm ? formAlm.LONGITUD_ALM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Longitud<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_longitud" style={{ display: 'none' }}><strong>Falta completar (Longitud)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_latitud" name="txt_latitud"
                                                type="text"
                                                maxLength={30}
                                                defaultValue={formAlm ? formAlm.LATITUD_ALM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Latitud<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_latitud" style={{ display: 'none' }}><strong>Falta completar (Latitud)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_telefono" name="txt_telefono"
                                                type="text"
                                                maxLength={30}
                                                defaultValue={formAlm ? formAlm.TELEFONO_ALM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Teléfono<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Días<span className="text-danger">*</span></label>
                                        <div className="input-group h-100">
                                            <div className="input-group-prepend"></div>
                                            <SelectSimple
                                                id={'list_dias'}
                                                data-label="Dias"
                                                isMulti={true}
                                                options={ctgUsuarios}
                                                className={'selectnumeric'}
                                                value={valUs}
                                                placeholder={'Selecciona a los encargados del almacen'}
                                                onChange={(datos) => {
                                                    console.log('datos', datos);
                                                    console.log(ctgUsuarios);
                                                    setValUs(datos)
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                        </div>
                                        <span className="text-danger txtVacios list_dias" style={{ display: 'none' }}><strong>Falta completar (Días)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_celular" name="txt_celular"
                                                type="text"
                                                maxLength={30}
                                                defaultValue={formAlm ? formAlm.CELULAR_ALM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Celular<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_celular" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(GetPermisos.find(permiso => [2035].includes(permiso)) ? true : false) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickAlm}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default