import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Home } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_maximos_minimos_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Máximos y mínimos'} parent={[{ titulo: 'máximos y mínimos', link: '/maximos-minimos' }]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className='card-body'>
                                <div className='row'>
                                    <div className="col-xl-12 col-md-12">
                                        <Table
                                            noRutaDinamica={true}
                                            cargando={false}
                                            htmlBtnPag={
                                                <div className="btn-container">
                                                    {(GetPermisos.find(permiso => [27].includes(permiso)) ? true : false) ?
                                                        <button className='btn-nuevo' onClick={() => {
                                                            navigate('/maximos-minimos/nuevo')
                                                        }}>
                                                            <span className="text">Nuevo</span>
                                                            <div className="icon-container">
                                                                <div className="icon icon--left">
                                                                </div>
                                                                <div className="icon icon--right">
                                                                </div>
                                                            </div>
                                                        </button>
                                                        : ''
                                                    }

                                                </div>
                                            }
                                            headers={[
                                                {
                                                    nombre: 'CLAVE INTERNA ARTICULO/INSUMO', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                                        return <div>
                                                            <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_MMI', html: (d) => {
                                                        return <div>
                                                            <strong>{d.NOMBRE_MMI}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'TÍTULO', tipo: 'html', texto: 'TITULO_MMI', html: (d) => {
                                                        return <div>
                                                            <strong>{d.TITULO_MMI}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'PROVEEDOR', tipo: 'html', texto: 'TITULO_PRV', html: (d) => {
                                                        return <div>
                                                            <strong>{d.TITULO_PRV}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                                    html: (d) => {
                                                        return <div>
                                                            <BtnVer
                                                                onClick={() => {
                                                                    navigate(`/maximos-minimos/${d.ID_MMI}`)
                                                                }}
                                                            />
                                                            {(GetPermisos.find(permiso => [2056].includes(permiso)) ? true : false) ?
                                                                <BtnEliminar
                                                                    onClick={() => {
                                                                        notie.confirm({
                                                                            text: `Se eliminara <strong>${d.NOMBRE_MMI}</strong> ¿estas de acuerdo?`,
                                                                            submitText: 'Si, eliminar',
                                                                            cancelText: 'No, Cancelar',
                                                                            cancelCallback: function (value) {
                                                                            },
                                                                            submitCallback: function (value) {
                                                                                eliminar({ id: d.ID_MMI })
                                                                            },
                                                                        })
                                                                    }}
                                                                />
                                                                : ''
                                                            }
                                                        </div>
                                                    }
                                                }
                                            ]}
                                            totalField={'NR'} /* NR = número de resultados totales */
                                            path={'/rt_maximos_minimos_list'}
                                            refresh={esRefresh}
                                            body={{}}
                                            paginadorTop={true}
                                        >
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Default;