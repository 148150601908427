import React, { Fragment, useState, useEffect } from 'react'
import { Briefcase, CreditCard, Home } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from "react-router-dom";
import { GetPermisos } from '../common/getPermisos';
import Breadcrumb from '../common/breadcrumbv2'
import Switch from '../common/switch'
import FormDatosContacto from './empresa_form';
import FormDatosFiscales from './empresa_df_form';
import DatosFiscales from './empresa_df';
import Tools from '../../js/tools'
// import { BrowserRouter, Router, Route } from "react-router-dom";

const Default = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [selected, setSelected] = useState('dc')
    const [empresa, setEmpresa] = useState({})
    const secciones = {
        "dc": 0,
        "df": 1,
        "df-form": 1,
        "df-form-editar": 1
    }

    const onSelectedDf = () => {
        navigate(`/empresa/${params.idEm}`)
        setSelected('df')
    }

    const onSelectedDc = () => {
        navigate(`/empresa/${params.idEm}`)
        setSelected('dc')
    }

    useEffect(() => {
        let isSubscribed = true

        Tools.toolFetch({
            ruta: 'rt_empresa_detalle',
            method: 'POST',
            body: { id: params.idEm },
        }, (response) => {
            let datos = response.datos
            if (isSubscribed) {
                setEmpresa(datos)
            }
        })

        if (params.idDf == 'nuevo') {
            return setSelected('df-form')
        }

        if (params.idDf) {
            return setSelected('df-form-editar')
        }

        return () => isSubscribed = false
    }, [])

    return (
        <Fragment>
            {/* <Breadcrumb icon={Briefcase} title={empresa.NOMBRE_EM || 'Empresa'} parent={['empresa']} /> */}
            <Breadcrumb icon={Home} title={'Empresa'} parent={[{ titulo: 'empresa', link: '/empresa' }, { titulo: 'editar empresa', link: `/empresa/${props.esEditar ? 'editar-empresa' : params.idEm}` }]} />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-header d-flex'>
                            <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/empresa')
                                }}>Volver al inicio</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className="row theme-tab">
                    <Tabs className="col-sm-12" onSelect={() => null} selectedIndex={secciones[selected]}>
                        <TabList className="tabs tab-title">
                            <Tab>
                                <div onClick={onSelectedDc}>
                                    <Briefcase /> DATOS DE CONTACTO
                                </div>
                            </Tab>
                            {(GetPermisos.find(permiso => [13].includes(permiso)) ? true : false) ?
                                <Tab>
                                    <div onClick={onSelectedDf}>
                                        <CreditCard /> DATOS FISCALES</div>
                                </Tab>
                                : ''
                            }
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel>
                                <FormDatosContacto esEditar={true} />
                            </TabPanel>
                            {(GetPermisos.find(permiso => [13].includes(permiso)) ? true : false) ?
                                <TabPanel selected={true}>
                                    <Switch selected={selected}>
                                        <DatosFiscales value={'df'} setSelected={setSelected} />
                                        <FormDatosFiscales value={'df-form'} setSelected={setSelected} />
                                        <FormDatosFiscales value={'df-form-editar'} setSelected={setSelected} esEditar={true} />
                                    </Switch>
                                </TabPanel>
                                : ''
                            }
                        </div>
                    </Tabs>
                </div>                
            </div>

        </Fragment>)
}

export default Default;