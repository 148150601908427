import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Home } from 'react-feather';
import tools from '../../js/tools';

const Default = ({ esEditar, setFechaFormat }) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [idProduccion, setIdProduccion] = useState(0)
    const [recetas, setRecetas] = useState([])
    const [tiempos, setTiempos] = useState([])
    const [ingredientes, setIngredientes] = useState([])
    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {
        let idRecetas = {}

        Tools.toolFetch({
            ruta: 'rt_produccion_recetas',
            method: 'POST',
            body: { idPr: params.id }
        }, (response) => {
            if (!response.result.idProduccion) return navigate('/produccion')

            if (response.status !== 'OK') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }


            if (recetas.length) recetas.forEach(v => idRecetas[v.ID_RE] = { refresh: !v.esRefresh })

            setFechaFormat(esEditar ? (response.result.fechaFormat || '') : '')
            setIdProduccion(response.result.idProduccion)
            setRecetas(response.result.recetas.map(v => ({
                ...v,
                valTiempo: v.VAL_TIEMPO,
                formatTablaRefresh: true,
                esRefresh: idRecetas[v.ID_RE] ? idRecetas[v.ID_RE].refresh : true
            })))
        })
    }, [esEditar])

    const actualizarProd = (ingrList) => {
        const arr = []

        ingrList.forEach(v => {
            v.ingredientes.forEach(e => {
                arr.push({
                    idRe: v.id,
                    idIna: e.ID_INA,
                    cantidad: e.CANTIDAD,
                    merma: e.MERMA_PRR,
                    tiempo: e.TIEMPO_PRR
                })
            })
        });

        Tools.toolFetch({
            ruta: 'rt_produccion_actualizar',
            method: 'POST',
            body: { ingredientes: arr, idProd: idProduccion }
        }, (response) => {

        })
    }

    return (
        <Fragment>
            {recetas.map((v, index) =>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div class="col-md-12 mb-3">
                                <h5 className='title-card-general'>{v.TITULO_RE}</h5>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="justify-content-start d-flex align-items-center">
                                    <h6>Tiempo:</h6>
                                    <input className='input-time' autoComplete='off'
                                        data-label="nombre"
                                        id="txt_nombre" name="txt_nombre"
                                        value={recetas[index].valTiempo}
                                        type="number"
                                        disabled={!GetPermisos.find(permiso => [2096].includes(permiso))}
                                        maxLength={100}
                                        onChange={(e) => setRecetas(l => {
                                            let valor = tools.formatNumber(e.target.value)

                                            if (valor < 1) valor = 1
                                            if (valor > 99) valor = 99

                                            const arr = l.map((s, i) => i === index ? { ...s, valTiempo: valor, formatTablaRefresh: !s.formatTablaRefresh } : { ...s })

                                            setIngredientes(l => {
                                                const _ingredientes = l[index].ingredientes.map(v => ({ ...v, CANTIDAD: v.CANTIDAD_IR * valor, TIEMPO_PRR: valor }))

                                                l[index].ingredientes = _ingredientes

                                                actualizarProd(l)
                                                return l
                                            })

                                            return arr
                                        })}
                                        spellcheck="false"
                                        style={{ border: "1px solid #9A552E", color: "#9a552e", marginLeft: "20px", borderRadius: "30px" }}
                                    />
                                </div>

                                <Table
                                    noRutaDinamica={true}
                                    cargando={false}
                                    formatTablaRefresh={recetas[index].formatTablaRefresh}
                                    formatTabla={(datos) => ({ ...datos, CANTIDAD: datos.CANTIDAD_IR * recetas[index].valTiempo })}
                                    headers={[
                                        {
                                            nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                return <div style={{ textAlign: 'center' }}>
                                                    <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                                return <div>
                                                    <strong>{d.NOMBRE_INA}</strong><br />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'TÍTULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                                return <div>
                                                    <strong>{d.TITULO_INA}</strong><br />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'CANTIDAD',
                                            tipo: 'input',
                                            texto: 'CANTIDAD',
                                            disabled: true
                                        },
                                        {
                                            nombre: 'MERMA',
                                            tipo: 'input',
                                            texto: 'MERMA_PRR',
                                            disabled: !GetPermisos.find(permiso => [2096].includes(permiso)),
                                            valueOp: (d, v) => {
                                                let valor = v

                                                if (valor < 1) valor = 1
                                                if (valor > 9999) valor = 9999

                                                let _ingredientes = ingredientes[index].ingredientes.map(element => {
                                                    return d.ID_INA === element.ID_INA ? { ...element, MERMA_PRR: valor } : element
                                                })

                                                setIngredientes(l => {
                                                    l[index].ingredientes = _ingredientes

                                                    actualizarProd(l)
                                                    return l
                                                })

                                                return { MERMA_PRR: valor }
                                            }
                                        },
                                        {
                                            nombre: 'MINIMA', tipo: 'html', texto: 'UNIDAD_MINIMA', html: (d) => {
                                                return <div>
                                                    <strong>{d.UNIDAD_MINIMA}</strong><br />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'MAXIMA', tipo: 'html', texto: 'UNIDAD_MAXIMA', html: (d) => {
                                                return <div>
                                                    <strong>{d.UNIDAD_MAXIMA}</strong><br />
                                                </div>
                                            }
                                        }
                                    ]}
                                    totalField={'NR'} /* NR = número de resultados totales */
                                    path={'/rt_produccion_ingredientes'}
                                    refresh={v.esRefresh}
                                    results={(response) => {
                                        setIngredientes((l) => {
                                            l[index] = { id: v.ID_RE, ingredientes: response }
                                            return l
                                        })
                                    }}
                                    esPaginador={false}
                                    sinBuscador={true}
                                    body={{ esEditar: esEditar, idRe: v.ID_RE, idPr: idProduccion }}
                                >
                                </Table>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Default;