import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from 'react-router-dom'
import Produccion from './produccion_recetas'
import ProduccionHistorial from './produccion_historial'
import ProduccionSucursales from './produccion_sucursales'
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Tools from '../../js/tools'
import notie from 'notie'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { green } from '@mui/material/colors';

const Default = ({ esEditar, esHistorial }) => {
    const [valueTab, setValueTab] = useState(0);
    const [fechaFormat, setFechaFormat] = useState('')
    const [sucursales, setSucursales] = useState([])
    const [formPag, setFormPag] = useState(true)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_produccion_list',
            method: 'POST',
        }, (response) => {
            setSucursales(response.datos)
        })

        setValueTab(0)
    }, [esEditar])

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={`Producción ${esEditar ? fechaFormat : ''}`} parent={[{ titulo: 'producción', link: '/produccion' }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <div className='card'>
                            <div className="card-header d-flex gap-3 mb-0">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/produccion')
                                }}>Volver al inicio</button>
                            </div>
                        </div>
                    </div>
                    <Box>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                    style={{ color: "#7E0000" }}
                                    textColor='inherit'
                                    onChange={handleChange}
                                    aria-label="lab API tabs example">
                                    {sucursales.map((e, index) => <Tab icon={<BarChart2 />} iconPosition="start" label={e.TITULO_SUC} value={index} />)}
                                    <Tab icon={<BarChart2 />} iconPosition="start" label='Producción' value={sucursales.length + 1} />
                                    {!esEditar ? <Tab icon={<Briefcase />} iconPosition="start" label='Historial' value={sucursales.length + 2} /> : ''}
                                </TabList>
                            </Box>
                            <div className="tab-content-cls">
                                {sucursales.map((v, index) =>
                                    <TabPanel value={index}>
                                        <ProduccionSucursales
                                            esEditar={esEditar}
                                            setFechaFormat={setFechaFormat}
                                            idSuc={v.ID_SUC}
                                        />
                                    </TabPanel>
                                )}
                                <TabPanel value={sucursales.length + 1}>
                                    <Produccion
                                        esEditar={esEditar}
                                        setFechaFormat={setFechaFormat}
                                    />
                                </TabPanel>
                                {!esEditar ?
                                    <TabPanel value={sucursales.length + 2}>
                                        <ProduccionHistorial />
                                    </TabPanel>
                                    : ''
                                }
                            </div>
                        </TabContext>
                    </Box>
                    {/* <Tabs className="col-sm-12" defaultTab={selectedTab.toString()}>
                        <TabList className="tabs tab-title">
                            <Tab className="current" tabFor="0">
                                <BarChart2 />Datos
                            </Tab>
                            <Tab tabFor="1">
                                <Briefcase />Insumos/Articulos
                            </Tab>
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel tabId="0">
                                <Recetas listArtIns={listArtIns} setListArtIns={setListArtIns} esEditar={esEditar} formRec={formRec} setFormRec={setFormRec} procedimiento={procedimiento} setProcedimiento={setProcedimiento} setSelectedTab={setSelectedTab} />
                            </TabPanel>
                            <TabPanel tabId="1">
                                <RecetasArtIns listArtIns={listArtIns} setListArtIns={setListArtIns} formRec={formRec} esEditar={esEditar} />
                            </TabPanel>
                        </div>
                    </Tabs> */}
                </div>
            </div>
        </Fragment>
    )
}


export default Default;