import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Home } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_direccion_fiscal_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Direcciones Fiscales'} parent={[{ titulo: 'direcciones fiscales', link: '/direccion-fiscal' }]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-xl-12 col-md-12">
                                    <Table
                                        noRutaDinamica={true}
                                        cargando={false}
                                        htmlBtnPag={
                                            <div className="btn-container">
                                                {(GetPermisos.find(permiso => [19].includes(permiso)) ? true : false) ?
                                                    <button className='btn-nuevo' onClick={() => {
                                                        navigate('/direccion-fiscal/nuevo')
                                                    }}>
                                                        <span className="text">Nuevo</span>
                                                        <div className="icon-container">
                                                            <div className="icon icon--left">
                                                            </div>
                                                            <div className="icon icon--right">
                                                            </div>
                                                        </div>
                                                    </button>
                                                    : ''
                                                }
                                            </div>}
                                        headers={[
                                            {
                                                nombre: 'ALIAS', tipo: 'html', texto: 'ALIAS_DIF', html: (d) => {
                                                    return <div>
                                                        <strong>{d.ALIAS_DIF}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'CALLE', tipo: 'html', texto: 'CALLE_DIF', html: (d) => {
                                                    return <div>
                                                        <strong>{d.CALLE_DIF}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'COLONIA', tipo: 'html', texto: 'COLONIA_DIF', html: (d) => {
                                                    return <div>
                                                        <strong>{d.COLONIA_DIF}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'RAZON SOCIAL', tipo: 'html', texto: 'RAZON_SOCIAL_DIF', html: (d) => {
                                                    return <div>
                                                        <strong>{d.RAZON_SOCIAL_DIF}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                                html: (d) => {
                                                    return <div>
                                                        <BtnVer
                                                            onClick={() => {
                                                                navigate(`/direccion-fiscal/${d.ID_DIF}`)
                                                            }}
                                                        />
                                                        {(GetPermisos.find(permiso => [2047].includes(permiso)) ? true : false) ?
                                                            <BtnEliminar
                                                                onClick={() => {
                                                                    notie.confirm({
                                                                        text: `Se eliminara <strong>${d.NOMBRE_DIF}</strong> ¿estas de acuerdo?`,
                                                                        submitText: 'Si, eliminar',
                                                                        cancelText: 'No, Cancelar',
                                                                        cancelCallback: function (value) {
                                                                        },
                                                                        submitCallback: function (value) {
                                                                            eliminar({ id_dif: d.ID_DIF })
                                                                        },
                                                                    })
                                                                }}
                                                            /> : ''
                                                        }
                                                    </div>
                                                }
                                            }
                                        ]}
                                        totalField={'NR'} /* NR = número de resultados totales */
                                        path={'/rt_direccion_fiscal_list'}
                                        refresh={esRefresh}
                                        body={{}}
                                        paginadorTop={true}
                                    >
                                    </Table>
                                </div>                    
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Default;