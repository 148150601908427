import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Home } from 'react-feather';
import { GetPermisos } from '../common/getPermisos';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formMaxMin, setFormMaxMin] = useState({})
    const [ctgInsArt, setCtgInsArt] = useState([])
    const [ctgUnidades, setCtgUnidades] = useState([])
    const [ctgProveedores, setCtgProveedores] = useState([])
    const [ctgAlmacenes, setctgAlmacenes] = useState([])
    const [valDia, setValDia] = useState([])
    const [ctgUsuarios, setCtgUsuarios] = useState([])
    const [valTipo, setValTipo] = useState(0)
    const [mostrarDias, setMostrarDias] = useState(false)
    // const [ctgEstados, setCtgEstados] = useState([])
    // const [ctgCiudades, setCtgCiudades] = useState([])
    // const [valCiudad, setValCiudad] = useState([])
    const navigate = useNavigate()
    const params = useParams();
    const [catalogos, setCatalogos] = useState({})

    useEffect(() => {
        return Tools.toolFetch({
            ruta: 'rt_maximos_minimos_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            if (response.status === 'OK') {
                setFormMaxMin(response.result[0])
                setCtgInsArt(response.ctgArtIns)
                setctgAlmacenes(response.ctgAlm)
                setCtgUsuarios(response.usuarios.datos)
                setCtgUnidades(response.ctgMedida)
                setCtgProveedores(response.ctgProveedores)
            }
        })

    }, [])

    const onClickInv = () => {
        const form = Tools.getForm('formMaxMin', { ignore: ['txt_num_ext', 'txt_descripcion'] })

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.id = params.id
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_maximos_minimos_nuevo' : 'rt_maximos_minimos_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/maximos-minimos')
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    function onClickArtIns(e) {
        return Tools.toolFetch({
            ruta: 'rt_ctg_articulos_insumos',
            method: 'POST',
            body: { tipo: e.item.clave },
        }, (response) => {
            if (response.estatus === 'OK') {
                setCtgInsArt(response.datos)
                setFormMaxMin(d => { return { ...d, TIPO_INA: e.item.clave } })
            }
        })
    }

    function onClickPrvArtIns(e) {
        setFormMaxMin(d => { return { ...d, ID_INA_MMI: e.item.clave } })
        return Tools.toolFetch({
            ruta: 'rt_ctg_proovedores_artins',
            method: 'POST',
            body: { id_ina: e.item.clave },
        }, (response) => {
            if (response.estatus === 'OK') {
                setCtgProveedores(response.datos)
            }
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Máximos y mínimos'} parent={[{ titulo: 'máximos y mínimos', link: '/maximos-minimos' }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formMaxMin' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex gap-3">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/maximos-minimos')
                                }}>Volver al inicio</button>
                            </div>
                            <div className="card-body">
                                <div className='row'>

                                    <div class="col-md-12 mb-3">
                                        <h5 className='title-card-general'>Datos generales</h5>
                                    </div>
                                    {/* <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Clave interna"
                                                autoComplete='off'
                                                id="txt_clave" name="txt_clave"
                                                type="text"
                                                defaultValue={formMaxMin ? formMaxMin.CLAVE_INTERNA_MMI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-book" aria-hidden="true"></i> Clave interna<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_clave" style={{ display: 'none' }}><strong>Falta completar (Clave interna)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_almacen"
                                                name="clave_almacen"
                                                datos={ctgAlmacenes.map(v => { return { clave: v.ID_ALM, label: v.TITULO_ALM } })}
                                                value={formMaxMin ? formMaxMin.ID_ALM_MMI : ''}
                                                onChange={(e) => { setFormMaxMin(v => { return { ...v, ID_ALM_MMI: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Almacen<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_almacen" style={{ display: 'none' }}><strong>Falta completar (Almacen)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Nombre"
                                                autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                maxLength={150}
                                                defaultValue={formMaxMin ? formMaxMin.NOMBRE_MMI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-book" aria-hidden="true"></i> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Titulo"
                                                autoComplete='off'
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                maxLength={150}
                                                defaultValue={formMaxMin ? formMaxMin.TITULO_MMI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> TÍtulo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (TÍtulo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_tipo"
                                                name="clave_tipo"
                                                datos={[{ clave: 1, label: 'Insumos' }, { clave: 2, label: 'Articulos' }]}
                                                value={formMaxMin ? formMaxMin.TIPO_MMI : ''}
                                                // onChange={(e) => { setFormMaxMin(v => { return { ...v, ID_AUDITOR_US_MMI: e.item.clave } }) }}
                                                onChange={onClickArtIns}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Tipo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_tipo" style={{ display: 'none' }}><strong>Falta completar (Tipo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_artins"
                                                name="clave_artins"
                                                datos={ctgInsArt.map(v => { return { clave: v.ID_INA, label: `${v.CLAVE_INTERNA_INA} - ${v.TITULO_INA}` } })}
                                                value={formMaxMin ? formMaxMin.ID_INA_MMI : ''}
                                                // onChange={(e) => { setFormMaxMin(v => { return { ...v, ID_AUDITOR_US_MMI: e.item.clave } }) }}
                                                // onChange={e => { setFormMaxMin(d => { return { ...d, ID_INA_MMI: e.item.clave } }) }}
                                                onChange={onClickPrvArtIns}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Articulo/Insumo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_artins" style={{ display: 'none' }}><strong>Falta completar (Articulo/Insumo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_prv"
                                                name="clave_prv"
                                                datos={ctgProveedores}
                                                value={formMaxMin ? formMaxMin.ID_PRV_MMI : ''}
                                                // onChange={(e) => { setFormMaxMin(v => { return { ...v, ID_AUDITOR_US_MMI: e.item.clave } }) }}
                                                // onChange={e => { setFormMaxMin(d => { return { ...d, ID_INA_MMI: e.item.clave } }) }}
                                                onChange={(e) => { setFormMaxMin(s => ({ ...s, ID_PRV_MMI: e.item.clave })) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Proveedores<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_prv" style={{ display: 'none' }}><strong>Falta completar (Proveedores)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="rango minimo"
                                                autoComplete='off'
                                                id="txt_rango_min" name="txt_rango_min"
                                                type="text"
                                                maxLength={10}
                                                defaultValue={formMaxMin ? formMaxMin.RANGO_MIN_MMI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Rango minimo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_rango_min" style={{ display: 'none' }}><strong>Falta completar (Rango minimo permitida)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_auditor"
                                                name="clave_auditor"
                                                datos={ctgUsuarios.map(v => { return { clave: v.ID_UC, label: `${v.NOMBRES_UC} ${v.APELLIDOS_UC}` } })}
                                                value={formMaxMin ? formMaxMin.ID_AUDITOR_US_MMI : ''}
                                                onChange={(e) => { setFormMaxMin(v => { return { ...v, ID_AUDITOR_US_MMI: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Auditor<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_auditor" style={{ display: 'none' }}><strong>Falta completar (Auditor)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_supervisor"
                                                name="clave_supervisor"
                                                datos={ctgUsuarios.map(v => { return { clave: v.ID_UC, label: `${v.NOMBRES_UC} ${v.APELLIDOS_UC}` } })}
                                                value={formMaxMin ? formMaxMin.ID_SUPERVISOR_US_MMI : ''}
                                                onChange={(e) => { setFormMaxMin(v => { return { ...v, ID_SUPERVISOR_US_MMI: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Supervisor<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_supervisor" style={{ display: 'none' }}><strong>Falta completar (Supervisor)</strong></span>
                                    </div> */}
                                    {esEditar ?
                                        <Fragment>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input
                                                        data-label="Promedio mensual"
                                                        autoComplete='off'
                                                        // id="txt_prom_men" name="txt_prom_men"
                                                        type="text"
                                                        // defaultValue={formMaxMin ? formMaxMin.PROMEDIO_MENSUAL_MMI : ''}
                                                        defaultValue={0}
                                                        maxLength={10}
                                                        onChange={(v) => { v.target.value = 0 }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Promedio mensual<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_prom_men" style={{ display: 'none' }}><strong>Falta completar (Promedio mensual)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input
                                                        data-label="Promedio semanal"
                                                        autoComplete='off'
                                                        // id="txt_prom_sem" name="txt_prom_sem"
                                                        type="text"
                                                        // defaultValue={formMaxMin ? formMaxMin.PROMEDIO_SEMANAL_MMI : ''}
                                                        defaultValue={0}
                                                        maxLength={10}
                                                        onChange={(v) => { v.target.value = 0 }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Promedio semanal<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_prom_sem" style={{ display: 'none' }}><strong>Falta completar (Promedio semanal)</strong></span>
                                            </div>
                                        </Fragment>
                                        : ''}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Cantidad maxima"
                                                autoComplete='off'
                                                id="txt_cantidad_max" name="txt_cantidad_max"
                                                type="text"
                                                maxLength={10}
                                                defaultValue={formMaxMin ? formMaxMin.CANTIDAD_MAX_MMI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Cantidad maxima permitida<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_cantidad_max" style={{ display: 'none' }}><strong>Falta completar (Cantidad maxima)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                id="clave_unidad_max"
                                                name="clave_unidad_max"
                                                datos={ctgUnidades}
                                                value={formMaxMin ? formMaxMin.ID_CANT_MAX_CU_MMI : ''}
                                                onChange={e => { setFormMaxMin(d => { return { ...d, ID_CANT_MAX_CU_MMI: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Medida maxima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_unidad_max" style={{ display: 'none' }}><strong>Falta completar (Medida maxima permitida)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Cantidad minima"
                                                autoComplete='off'
                                                id="txt_cantidad_min" name="txt_cantidad_min"
                                                type="text"
                                                maxLength={10}
                                                defaultValue={formMaxMin ? formMaxMin.CANTIDAD_MIN_MMI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Cantidad minima permitida<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_cantidad_min" style={{ display: 'none' }}><strong>Falta completar (Cantidad minima permitida)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                id="clave_unidad_min"
                                                name="clave_unidad_min"
                                                datos={ctgUnidades}
                                                value={formMaxMin ? formMaxMin.ID_CANT_MIN_CU_MMI : ''}
                                                onChange={e => { setFormMaxMin(d => { return { ...d, ID_CANT_MIN_CU_MMI: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Medida minima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_unidad_min" style={{ display: 'none' }}><strong>Falta completar (Medida minima)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_tipo"
                                                name="clave_tipo"
                                                datos={[{ clave: 0, label: 'Fin de semana' }, { clave: 1, label: 'Por dia' }]}
                                                value={valTipo}
                                                // onChange={}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Tipo de inventario<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_tipo" style={{ display: 'none' }}><strong>Falta completar (Tipo de inventario)</strong></span>
                                    </div>
                                    {mostrarDias ?
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationTooltip02">Días<span className="text-danger">*</span></label>
                                            <div className="input-group h-100">
                                                <div className="input-group-prepend"></div>
                                                <SelectSimple
                                                    id={'list_dias'}
                                                    // name={'list_dias'}
                                                    data-label="Dias"
                                                    isMulti={true}
                                                    options={ctgDias}
                                                    className={'selectnumeric'}
                                                    // value={preciosSuc.map((v) => ({label: v.NOMBRE_SUC, value: v.ID_SUC_PRE}))}
                                                    value={valDia}
                                                    onChange={(datos) => {
                                                        console.log('datos', datos);
                                                        console.log(ctgDias);
                                                        setValDia(datos)
                                                    }}
                                                    required
                                                    spellcheck="false"
                                                />
                                            </div>
                                            <span className="text-danger txtVacios list_dias" style={{ display: 'none' }}><strong>Falta completar (Días)</strong></span>
                                        </div> : ''} */}
                                    <div className="col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea
                                                data-label="Comentarios"
                                                autoComplete='off'
                                                id="txt_descripcion" name="txt_descripcion"
                                                type="text"
                                                maxLength={1000}
                                                defaultValue={formMaxMin ? formMaxMin.DESCRIPCION_MMI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Comentarios<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Comentarios)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2055].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [27].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickInv}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default