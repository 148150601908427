import React, { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Home } from 'react-feather';

const Default = ({ esEditar, esHistorial }) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_recetas_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'ID', tipo: 'html', texto: 'ID_PR', html: (d) => {
                                            return <div style={{ textAlign: 'center' }}>
                                                <strong>{d.ID_PR}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'FECHA', tipo: 'html', texto: 'FECHA_FORMAT', html: (d) => {
                                            return <div>
                                                <strong>{d.FECHA_FORMAT}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                        html: (d) => {
                                            return <div>
                                                <BtnVer
                                                    onClick={() => {
                                                        navigate(`/produccion/${d.ID_PR}`)
                                                    }}
                                                />
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_produccion_historial_list'}
                                refresh={esRefresh}
                                body={{}}
                                paginadorTop={true}
                            >
                            </Table>
                        </div>                        
                    </div>    
                </div>
            </div>
        </Fragment>
    );
};

export default Default;