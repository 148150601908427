import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Briefcase } from 'react-feather';
import tools from '../../js/tools';

const Default = ({ esEditar, setFechaFormat, idSuc }) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [idProduccion, setIdProduccion] = useState(0)
    const [recetas, setRecetas] = useState([])
    const [tiempos, setTiempos] = useState([])
    const [ingredientes, setIngredientes] = useState([])
    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {
        let idRecetas = {}

        Tools.toolFetch({
            ruta: 'rt_produccion_recetas',
            method: 'POST',
            body: { idPr: params.id }
        }, (response) => {

        })
    }, [esEditar])

    const actualizarProd = (ingrList) => {
        const arr = []

        ingrList.forEach(v => {
            v.ingredientes.forEach(e => {
                arr.push({
                    idRe: v.id,
                    idIna: e.ID_INA,
                    cantidad: e.CANTIDAD,
                    merma: e.MERMA_PRR,
                    tiempo: e.TIEMPO_PRR
                })
            })
        });

        Tools.toolFetch({
            ruta: 'rt_produccion_actualizar',
            method: 'POST',
            body: { ingredientes: arr, idProd: idProduccion }
        }, (response) => {

        })
    }

    return (
        <Fragment>
            {recetas.map((v, index) =>
                <div className="card">
                    <div className="col-xl-12 col-md-12">
                        <div className="card-header d-flex justify-content-center p-3">
                            <h4>{v.TITULO_RE}</h4>
                        </div>
                        <div className="mt-3 px-4">
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                formatTablaRefresh={recetas[index].formatTablaRefresh}
                                formatTabla={(datos) => ({ ...datos, CANTIDAD: datos.CANTIDAD_IR * recetas[index].valTiempo })}
                                headers={[
                                    {
                                        nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                            return <div style={{ textAlign: 'center' }}>
                                                <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.NOMBRE_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'TÍTULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.TITULO_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'CANTIDAD',
                                        tipo: 'input',
                                        texto: 'CANTIDAD',
                                        disabled: true
                                    },
                                    {
                                        nombre: 'MERMA',
                                        tipo: 'input',
                                        texto: 'MERMA_PRR',
                                        disabled: !GetPermisos.find(permiso => [2096].includes(permiso)),
                                        valueOp: (d, v) => {
                                            let valor = v

                                            if (valor < 1) valor = 1
                                            if (valor > 9999) valor = 9999

                                            let _ingredientes = ingredientes[index].ingredientes.map(element => {
                                                return d.ID_INA === element.ID_INA ? { ...element, MERMA_PRR: valor } : element
                                            })

                                            setIngredientes(l => {
                                                l[index].ingredientes = _ingredientes

                                                actualizarProd(l)
                                                return l
                                            })

                                            return { MERMA_PRR: valor }
                                        }
                                    },
                                    {
                                        nombre: 'MINIMA', tipo: 'html', texto: 'UNIDAD_MINIMA', html: (d) => {
                                            return <div>
                                                <strong>{d.UNIDAD_MINIMA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'MAXIMA', tipo: 'html', texto: 'UNIDAD_MAXIMA', html: (d) => {
                                            return <div>
                                                <strong>{d.UNIDAD_MAXIMA}</strong><br />
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_produccion_ingredientes'}
                                refresh={v.esRefresh}
                                results={(response) => {
                                    setIngredientes((l) => {
                                        l[index] = { id: v.ID_RE, ingredientes: response }
                                        return l
                                    })
                                }}
                                esPaginador={false}
                                sinBuscador={true}
                                body={{ esEditar: esEditar, idRe: v.ID_RE, idPr: idProduccion }}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Default;